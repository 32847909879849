import React from "react";

function ImagedItem({image, title,description,longestTitle}) {
  return (
    <div className="imaged-item">
        <img
          className="imaged-item__image" alt=''
          src={image}
        />
        <div className ="imaged-item__title-container">
        <h3 className="imaged-item__title">{title}</h3>
        <h3 className="imaged-item__title-placer imaged-item__title">{longestTitle+"ab"}</h3>
        <p className="imaged-item__description">
         {description}
        </p>
        </div>
      </div>
  );
}

export default ImagedItem;
