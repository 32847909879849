import React from "react";
const Home = React.forwardRef(function Home(props,ref) {

  return (
    <section id = "home" ref={ref} className="home-section">
        <div className ="home-section__text-container">
      <h1 className="home-section__header">Business setup in Dubai</h1>
      <p className="home-section__description"><span className="">Keystone Group</span> provides services for companies formation, visa and Emirates ID processing, bank account opening</p>
      </div>
    </section>
  );
});

export default Home;
