import React from "react";

function TextItem({title,description}) {
  return (
    <div className="text-item">
        <h3 className="text-item__title">{title}</h3>
        <p className="text-item__description">
         {description}
        </p>
      </div>
  );
}

export default TextItem;
