import React from "react";
import ImagedItem from "../components/ImagedItem.tsx";
import ItemsSection from "../components/ItemsSection.jsx";

const CompaniesFormation = React.forwardRef(function CompaniesFormation(props,ref) {
  let data = {
    children: [
      {
        title: "Free zone companies",
        description:
          "Setup in one of the UAE free zones and take advantage of zero corporate tax and hassle-free company maintenance",
        image:
          "./../person.webp",
      },
      {
        title: "Mainland companies",
        description: "Setup in mainland Dubai to target the Dubai customers with no restrictions",
        image:
          "./../persona.webp",
      },
      
    ],
    title: "COMPANIES FORMATION",
    description:"",
  };
  let longestTitle = "";
data.children.forEach(({title})=>{
    if(title.length>longestTitle.length){
        longestTitle = title;
    }
})
  return (
    <ItemsSection
    id ="companies-formation"
      className="companies-formation-section"
      ref={ref}
      title={data.title}
      description="">
      {data.children.map(({ title, image, description },index) => (
        <ImagedItem title={title} key = {index} description={description} image={image} longestTitle={longestTitle}/>
      ))}
    </ItemsSection>
  );
}
);
export default CompaniesFormation;
