import "./reset.css";
import "./App.css";
import DocsLegalisation from "./sections/DocsLegalisation.jsx";
import CompaniesFormation from "./sections/CompaniesFormation.jsx";
import BankAccountOpening from "./sections/BankAccountOpening.jsx";
import VisasEmiratesId from "./sections/VisasEmiratesId.jsx";
import Contact from "./sections/Contact.jsx";
import SideMenu from "./components/SideMenu.tsx";
import useElementOnScreen from "./useElementOnScreen.jsx";
import React from "react";
import { Link } from "@mui/material";
import Home from "./sections/Home.jsx";

function App() {
  function changeLanguage(e) {
    if (select.current.value === "Русский") {
      window.location.replace("https://keystone-group.ae/ru");
    }
  }
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const select = React.useRef(null);
  const [whatVisible, refs] = useElementOnScreen(
    {
      root: null,
      rootMargin: "0px",
      threshold: window.innerWidth > 1050 ? 0.7 : 0.5,
    },
    {
      root: null,
      rootMargin: "0px",
      threshold: window.innerWidth > 1050 ? 0.7 : 0.5,
    },
    {
      root: null,
      rootMargin: "0px",
      threshold: window.innerWidth > 1050 ? 0.7 : 0.3,
    },
    {
      root: null,
      rootMargin: "0px",
      threshold: window.innerWidth > 1050 ? 0.7 : 0.5,
    },
    {
      root: null,
      rootMargin: "0px",
      threshold: window.innerWidth > 1050 ? 0.45 : 0.18,
    },
    {
      root: null,
      rootMargin: "0px",
      threshold: window.innerWidth > 1050 ? 0.51 : 0.38,
    }
  );
  const language = "EN";
  const links = [
    {
      name: "Home",
      id: "home",
    },
    {
      name: "Companies formation",
      id: "companies-formation",
    },
    {
      name: "Visas and Emirates ID",
      id: "visas-emirates-id",
    },
    {
      name: "Bank account opening",
      id: "bank-account-opening",
    },
    {
      name: "Legalization of documents",
      id: "legalization-of-documents",
    },
    {
      name: "Contact",
      id: "contact",
    },
  ];

  return (
    <div className="App">
      <nav className="side-menu">
        <div
          className="side-menu__open-button"
          onClick={() => {
            setIsMenuOpen(true);
            document.body.classList.add("body_no-scroll");
          }}
        >
          <svg viewBox="0 0 24 24" fill="white" width="28px" height="28px">
            <g>
              <path fillRule="evenodd" d="M4 8h16V6H4z"></path>
              <path fillRule="evenodd" d="M4 13.096h16v-2.001H4z"></path>
              <path fillRule="evenodd" d="M4 18.346h16v-2H4z"></path>
            </g>
          </svg>
        </div>
        <h1 className="side-menu__header">Keystone Group</h1>
        <div
          className={
            isMenuOpen
              ? "side-menu__links-container side-menu__links-container_opened"
              : "side-menu__links-container "
          }
        >
          <ul className="side-menu__links">
            {links.map((item, index) => {
              return (
                <li
                  key={index}
                  className={
                    whatVisible === index
                      ? "side-menu__link side-menu__link-active"
                      : "side-menu__link"
                  }
                >
                  <a
                    onClick={() => {
                      setIsMenuOpen(false);
                      document.body.classList.remove("body_no-scroll");
                    }}
                    href={"#" + item.id}
                  >
                    {item.name}
                  </a>
                </li>
              );
            })}
          </ul>
          <div className="side-menu__language">
            {" "}
            <p>Language:</p>
            <select
              ref={select}
              id="language"
              onChange={(e) => {
                changeLanguage(e);
              }}
            >
              <option>English</option>
              <option
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Русский
              </option>
            </select>
          </div>
          <div
            className="side-menu__close-button"
            onClick={() => {
              setIsMenuOpen(false);
              document.body.classList.remove("body_no-scroll");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#a9a9a9"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M19.219 5.22a.75.75 0 0 0-1.061 0l-5.939 5.939-5.939-5.94a.75.75 0 1 0-1.061 1.062l5.939 5.939-5.939 5.939a.752.752 0 0 0 0 1.06.752.752 0 0 0 1.061 0l5.939-5.938 5.939 5.939a.75.75 0 1 0 1.061-1.061l-5.939-5.94 5.939-5.938a.75.75 0 0 0 0-1.061"
              ></path>
            </svg>
          </div>
        </div>
      </nav>

      <div>Spacer</div>
      <Home ref={refs[0]}></Home>
      <CompaniesFormation ref={refs[1]}></CompaniesFormation>
      <VisasEmiratesId ref={refs[2]}></VisasEmiratesId>
      <BankAccountOpening ref={refs[3]}></BankAccountOpening>
      <DocsLegalisation ref={refs[4]}></DocsLegalisation>
      <Contact ref={refs[5]}></Contact>
    </div>
  );
}

export default App;
