import React from "react";
import ImagedItem from "../components/ImagedItem.tsx";
import ItemsSection from "../components/ItemsSection.jsx";

const BankAccountOpening  = React.forwardRef(function BankAccountOpening(props,ref) {
  let data = {
    children: [
      {
        title: "Corporate bank account",
        description:
          "Quick opening of a bank account for your company and direct communications with compliance departments of major UAE banks",
        image:
          "./../safe.webp",
      },
      {
        title: "Personal bank account",
        description: "Hassle-free opening of a personal bank account for residents and non-residents in major UAE banks",
        image:
          "./../cards.webp",
      },
      
    ],
    title: "BANK ACCOUNT OPENING",
    description:"",
  };
  let longestTitle = "";
data.children.forEach(({title})=>{
    if(title.length>longestTitle.length){
        longestTitle = title;
    }
})
  return (
    <ItemsSection
    id ="bank-account-opening"
    ref={ref}
      className="bank-account-opening-section"
      title={data.title}
      description="">
      {data.children.map(({ title, image, description },index) => (
        <ImagedItem key = {index} title={title} description={description} image={image} longestTitle={longestTitle}/>
      ))}
    </ItemsSection>
  );
});

export default BankAccountOpening;
