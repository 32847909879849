import React, { useEffect, useRef } from "react";
import GoogleMapReact from 'google-map-react';
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { Checkbox, FormControlLabel } from "@mui/material";


const SimpleMap = function(){
  const defaultProps = {
    center: { lat: 25.221699516833656, lng: 55.2855506572104},
    zoom: 13
  };
const handleApiLoaded = (map,maps)=>{
  const marker = new maps.Marker({
    position: { lat: 25.221699516833656, lng: 55.2855506572104},
    map,
    title:"Hello World!"
});
}
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAuUjDGFSH_TN2WhIcXT7XqaPqhEfTEj4U "}}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
      </GoogleMapReact>
    </div>
  );
}
const StyledCheckbox = styled(FormControlLabel)({
  "&": {
    marginBottom: "28px",
  },
  "& .MuiFormControlLabel-label": {
    fontFamily: "Raleway",
    color: "rgb(145,145,145)",
  },
  "&:hover .MuiFormControlLabel-label": {
    fontFamily: "Raleway",
    color: "rgb(180,180,180)",
  },
  "& .MuiCheckbox-root": {
    color: "rgb(145,145,145)",
  },
  "& .MuiCheckbox-root.Mui-checked": {
    color: "rgb(145,145,145)",
  },
});
const CssTextField = styled(TextField)({
  "&": {
    marginBottom: "28px",
  },
  "& label.Mui-focused": {
    color: "rgb(145,145,145)",
  },
  "& label.Mui-error": {
    color: "rgb(145,145,145)",
  },
  "& label.Mui-filled": {
    backgroundColor: "red",
  },
  "& label": {
    color: "rgb(145,145,145)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "rgb(145,145,145)",
  },
  "& .MuiOutlinedInput-root": {
    fontFamily: "Raleway !important",
    color: "rgb(145,145,145) !important",
    "& fieldset": {
      borderColor: "black",
    },
    "&.Mui-error fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused.Mui-error fieldset": {
      borderColor: "rgb(145,145,145)",
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    color: "rgb(180,180,180) !important",
    "& fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgb(145,145,145)",
    },
  },
});
const Contact = React.forwardRef(function Contact(props,ref) {
  const [step, setStep] = React.useState(0);
  const [isSubscribed,setIsSubscribed] = React.useState(false);
  const [name, setName] = React.useState("");
  const [status, setStatus] = React.useState(undefined);
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [wasEmailChanged, setWasEmailChanged] = React.useState(false);
  const [wasMouseOver, setWasMouseOver] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);
  const [wasSubmit, setWasSubmit] = React.useState(false);
  const dialogContentRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dialogContentRef.current &&
        !dialogContentRef.current.contains(event.target) &&
        wasMouseOver
      ) {
        closeDialog();
      }
    }

    if (step !== 0) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [step, wasMouseOver]);
  function closeDialog() {
    setIsSending(false);
    setWasEmailChanged(false);
    setWasMouseOver(false);
    setWasSubmit(false);
    setMessage("");
    setStep(0);
    document.body.classList.remove("body_no-scroll");
  }
  function validateForm(e) {
    setWasSubmit(true);
    if (name.length !== 0 && message.length !== 0 && isEmailValid(email)) {
      setIsSending(true);
      fetch("https://keystone-group.ae/api/sendMessage", {
        method:"POST",
        body: JSON.stringify({name:name,email:email,message:message,isSubscribed:isSubscribed}),
        headers: {
          // Добавляем необходимые заголовки
          "Content-type": "application/json; charset=UTF-8",
        },
      }).then(() => {
        setStep(2);
        setStatus("success");
      })
      .catch((error) => {
        console.log(error);
        setStatus("error");
        setStep(2);
      });
    }
  }
  const EMAIL_REGEXP =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
  function isEmailValid(value) {
    return EMAIL_REGEXP.test(value);
  }

  return (
    <section id = "contact" ref={ref} className="contact-section">
      <h2>CONTACT</h2>
      <h3 className="contact-section__title">Keystone Group</h3>
      <p className="contact-section__description">
        The One Central, Building 3, DWTC, Dubai, UAE
      </p>
      <button
        className="button"
        onClick={() => {
          setStep(1);
          document.body.classList.add("body_no-scroll");
          setTimeout(() => setWasMouseOver(true), 100);
        }}
      >
        DROP US A LINE
      </button>
      <div className="contact__map-container">
        <SimpleMap>
          </SimpleMap>
          <a href="https://www.google.com/maps/dir/?api=1&destination=The%20Offices%203,%20One%20Central,%20Dubai" target="_blank" rel="noreferrer">
        <button className="button button_get-directions">
        <svg viewBox="0 0 24 24" fill="currentColor" width="18px" height="18px" data-ux="Icon" ><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M19.94 6.078l-7.273 14.546c-.129.265-.345.398-.648.398a.962.962 0 01-.17-.023.716.716 0 01-.557-.705V13.75H4.746a.716.716 0 01-.704-.557.736.736 0 01.045-.477.687.687 0 01.33-.341L18.962 5.1a.683.683 0 01.33-.08c.204 0 .375.073.511.217a.678.678 0 01.21.392.69.69 0 01-.073.448z" fill="currentColor"></path></svg></svg>
        GET DIRECTIONS
        </button>
        </a>
      </div>
      <div className="copyright">
      <h5 className="copyright__header">
      KEYSTONE GROUP
      </h5>
      <p className="copyright__info">
      COPYRIGHT © 2024 KEYSTONE GROUP - ALL RIGHTS RESERVED.
      </p>
      </div>
      
      {step === 1 ? (
        <dialog className="contact-dialog" open>
          <form
            className="contact-dialog__form"
            ref={dialogContentRef}
            onMouseEnter={() => {
              setWasMouseOver(true);
            }}
          >
            <div
              className="success-message__close-button"
              onClick={() => {
                closeDialog();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
              </svg>
            </div>
            <h3>Drop us a line!</h3>
            <div className="contact-dialog__form__inputs-container">
              <CssTextField
                autoComplete="off"
                fullWidth
                label="Name*"
                id="name"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                helperText={
                  name.length === 0 && wasSubmit
                    ? "Please fill this required field"
                    : ""
                }
                error={name.length === 0 && wasSubmit ? true : false}
              />
              <CssTextField
                autoComplete="off"
                fullWidth
                label="Email*"
                id="email"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                onBlur={() => {
                  setWasEmailChanged(true);
                }}
                helperText={
                  !isEmailValid(email) && (wasEmailChanged || wasSubmit)
                    ? "Please enter valid email address"
                    : ""
                }
                error={
                  !isEmailValid(email) && (wasEmailChanged || wasSubmit)
                    ? true
                    : false
                }
              />
              <CssTextField
                autoComplete="off"
                fullWidth
                multiline
                minRows={4}
                maxRows={4}
                label="Message*"
                id="message"
                value={message}
                onChange={(event) => {
                  setMessage(event.target.value);
                }}
                helperText={
                  message.length === 0 && wasSubmit
                    ? "Please fill this required field"
                    : ""
                }
                error={message.length === 0 && wasSubmit ? true : false}
              />
              <StyledCheckbox
                control={<Checkbox />}
                checked = {isSubscribed}
                onChange={(event)=>{
                  setIsSubscribed(event.target.checked);
                }}
                label="Sign up for our email list for updates, promotions, and more."
              />
              {!isSending ? (
                <button
                  className="button_centered button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    validateForm(e);
                  }}
                >
                  SEND
                </button>
              ) : (
                <button
                  disabled
                  className="button_centered button button_processing"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  SENDING
                </button>
              )}
            </div>
          </form>
        </dialog>
      ) : null}
      {step === 2 ? (
        <dialog className="contact-dialog" open>
          <div className="success-message" ref={dialogContentRef}>
            {status === "success" ? (
              <svg viewBox="0 0 24 24" fill="#0078D7" width="72" height="72">
                <path
                  fillRule="evenodd"
                  d="M12 4a8 8 0 0 1 8 8 8 8 0 0 1-8 8 8 8 0 0 1-8-8 8 8 0 0 1 8-8zm0 1.5A6.507 6.507 0 0 0 5.5 12c0 3.584 2.915 6.5 6.5 6.5 3.584 0 6.5-2.916 6.5-6.5S15.584 5.5 12 5.5zm3.316 4.249a.72.72 0 0 1 .195.533.719.719 0 0 1-.252.512l-4.099 3.491a.846.846 0 0 1-.56.209.845.845 0 0 1-.62-.268l-1.298-1.419a.723.723 0 0 1 .056-1.046.774.774 0 0 1 1.067.055l.845.931 3.6-3.053a.771.771 0 0 1 1.066.055z"
                ></path>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                width="50px"
                height="50px"
                fill="#F74A15"
                
              >
                <path stroke="#F74A15" strokeWidth={"2px"} d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 32.990234 
                15.986328 A 1.0001 1.0001 0 0 0 32.292969 16.292969 L 25 23.585938 L 17.707031 16.292969 
                A 1.0001 1.0001 0 0 0 16.990234 15.990234 A 1.0001 1.0001 0 0 0 16.292969 17.707031 
                L 23.585938 25 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 
                L 25 26.414062 L 32.292969 33.707031 A 1.0001 1.0001 0 1 0 33.707031 32.292969 
                L 26.414062 25 L 33.707031 17.707031 A 1.0001 1.0001 0 0 0 32.990234 15.986328 z" />
              </svg>
            )}
            {status === "success" ? (
              <p className="success-message__description">
                Thank you for your inquiry! We will get back to you within 48
              hours.
              </p>
            ) : (
              <p className="success-message__description">
                Sorry, some error occurred. Maybe it is connected with your internet connection. Please try again later.
              </p>
            )}
            <div
              className="success-message__close-button"
              onClick={() => {
                closeDialog();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
              </svg>
            </div>
          </div>
        </dialog>
      ) : null}
    </section>
  );
});

export default Contact;
