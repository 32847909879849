import React from "react";
import ImagedItem from "../components/ImagedItem.tsx";
import ItemsSection from "../components/ItemsSection.jsx";

const DocsLegalisation = React.forwardRef(function DocsLegalisation(props,firstRef) {
  let data = {
    children: [
      {
        title: "Legalization of powers of attorney",
        description:
          "Legalize a PoA issued by a UAE notary or attest a PoA directly at a Consulate",
        image:
          "./../diplomat.webp"
        },
      {
        title: "Appointment at Consulates",
        description: "Get your appointment date at a Consulate quickly ",
        image:
          "./../Kremlin.webp",
      },
      {
        title: "Legalization of corporate documents",
        description:
          "Legalize memorandums and articles of association, corporate resolutions, business licenses and other documents",
         image:
          "./../hierarchy.webp",
      },
      {
        title: "Legalization of marriage certificates",
        description:
          "Legalize marriage certificates for recognition of a UAE marriage abroad",
          image:
          "./../rings.webp",
       },
      {
        title: "Legalization of education documents",
        description:
          "Legalize diplomas and transcripts for the use in the UAE ",
          image:
          "./../bachelor.webp",
       },
      {
        title: "Documents notarization",
        description:
          "Get an appointment with a UAE notary to attest any document ",
          image:
          "./../claim.webp",
       },
    ],
    title: "LEGALIZATION OF DOCUMENTS",
    description:
      "The UAE has not signed to the Hague Convention on Apostille, which eliminates the need for consular legalization of documents, Therefore, for the use of UAE-issued documents abroad, they shall undergo a full legalization procedure through the UAE Ministry of Foreign Affairs and the Consulate of the country where documents will be used.\n\nDirect contacts at the UAE Ministry of Foreign Affairs and Consulates of over 100 countries enable Keystone Group to take care of all the formalities and legalize documents quickly and efficiently.",
  };
  let longestTitle = "";
  data.children.forEach(({ title }) => {
    if (title.length > longestTitle.length) {
      longestTitle = title;
    }
  });
  return (
    <ItemsSection
      id = "legalization-of-documents"
      ref={firstRef}
      className="docs-legalisation-section"
      title={data.title}
      description={
        <>
          The UAE has not signed to the Hague Convention on Apostille, which
          eliminates the need for consular legalization of documents, Therefore,
          for the use of UAE-issued documents abroad, they shall undergo a full
          legalization procedure through the UAE Ministry of Foreign Affairs and
          the Consulate of the country where documents will be used.
          <br />
          <br />
          Direct contacts at the UAE Ministry of Foreign Affairs and Consulates
          of over 100 countries enable
          <span> Keystone Group</span> to take care of all the formalities and
          legalize documents quickly and efficiently.
        </>
      }
    >
      {data.children.map(({ title, image, description }, index) => (
        <ImagedItem
          title={title}
          key={index}
          description={description}
          image={image}
          longestTitle={longestTitle}
        />
      ))}
    </ItemsSection>
  );
});

export default DocsLegalisation;
