import React, { useRef, useEffect, useState } from "react"
const useElementOnScreen = (...optionsList) => {
  let refs= [];
  refs.push(useRef(null));
  refs.push(useRef(null));
  refs.push(useRef(null));
  refs.push(useRef(null));
  refs.push(useRef(null));
  refs.push(useRef(null));
  const [ whatVisible, setWhatVisible] = useState(0)
  useEffect(() => {
    const observers = [];
    for(let i=0;i<optionsList.length;i++){
      const observer = new IntersectionObserver((entries) => {
        const [ entry ] = entries
        if(entry.isIntersecting){
          setWhatVisible(i);
        }
    
      }, optionsList[i]);
      if (refs[i].current) observer.observe(refs[i].current);
      observers[i] = observer;
    }
    return () => {
      for(let i=0;i<optionsList.length;i++){
        if (refs[i].current) observers[i].unobserve(refs[i].current);
      }
    }
  }, [...optionsList, ...refs])

  return [whatVisible,refs];
}

export default useElementOnScreen;