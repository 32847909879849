import React from "react";

function SideMenu() {
  return (
    <nav className="SideMenu">
        <h1>Keystone roup</h1>
    </nav>

  );
}

export default SideMenu;
