import React from "react";
import TextedItem from "../components/TextedItem.tsx";

const VisasEmiratesId = React.forwardRef(function VisasEmiratesId(props,ref) {
  let data = {
    children: [
      {
        title: "Employee visa",
        description:
          "Get your visa and Emirates ID if you are working for a UAE company",
      },
      {
        title: "Investor visa",
        description:
          "Get your visa and Emirates ID as a shareholder of a UAE company",
      },
      {
        title: "Golden visa",
        description:
          "Get your visa and Emirates ID if you are a real estate owner, entrepreneur, man of science or culture ",
      },
      {
        title: "Freelance visa",
        description: "Get your visa and Emirates ID if you are a freelancer ",
      },
    ],
    title: "VISAS AND EMIRATES ID",
  };
  let longestTitle = "";
  data.children.forEach(({ title }) => {
    if (title.length > longestTitle.length) {
      longestTitle = title;
    }
  });
  return (
    <section ref=  {ref}id = "visas-emirates-id" className=" visas-section">

      <div className="visas_text-container">
      <h2 className="items-section__header">VISAS AND <span className="nowrapper">EMIRATES ID</span></h2>
      <div className="visas_items-container">
       {data.children.map(({ title, description },index) => (
        <TextedItem key= {index} title={title} description={description}/>
      ))}
      </div>
      </div>
      <div className="visas_image-banner">

      </div>
    </section>
   
  );
});

export default VisasEmiratesId;
