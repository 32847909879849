import React from 'react';
const ItemsSection = React.forwardRef(function ItemsSection({className,title,description,children,id},ref){
return (
    <section id={id} ref = {ref} onScroll = {
     ()=> console.log("scrolling")   }
     className={"items-section " + className}>
      <h2 className="items-section__header">{title}</h2>
      <p className="items-section__description">
        {description}
      </p>
      <div className="imaged-item-container">
{children}
      </div>
    </section>
  );


});
export default ItemsSection;